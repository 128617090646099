import { cva, VariantProps } from 'cva'
import { ComponentProps, forwardRef } from 'react'

const badge = cva({
	base: 'flex w-fit items-center justify-center rounded px-1.5 font-bold uppercase leading-tight text-gray-light',
	variants: {
		size: {
			small: 'text-3xs h-[22px]',
			normal: 'text-xs h-[22px]',
		},
		highlighted: {
			false: 'bg-blue-darkish',
			true: 'bg-[#17428E] bg-opacity-80',
		},
	},
	defaultVariants: {
		size: 'normal',
		highlighted: false,
	},
})

type Props = ComponentProps<'span'> & VariantProps<typeof badge>

// TODO: Add support for multiple colours
const Badge = forwardRef<HTMLSpanElement, Props>(function Badge(
	{
		highlighted = false,
		size = 'normal',
		className,
		children,
		...props
	}: Props,
	ref
) {
	return (
		<span
			{...props}
			ref={ref}
			className={badge({ highlighted, size, className })}
		>
			{children}
		</span>
	)
})

export default Badge
